.main {
  display: flex;
  align-items: center;
  height: 98vh;
  font-size: 17px;
  flex-direction: column;
  width: 100%;
}

.iframe {
  width: 100vw;
  height: 100vh;
  border: none;
}

@media only screen and (max-width: 600px) {
  .main {
    align-items: flex-start;
  }
}
